<script setup lang="ts">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useNotificationsStore } from '@notifications/stores';

import EnquiryNotificationCard from '@notifications/components/EnquiryNotificationCard.vue';
const notificationsStore = useNotificationsStore();
const target = ref(null);

onClickOutside(target, () => (notificationsStore.isOpen = false));

notificationsStore.getNotifications();

function togglePanel() {
  notificationsStore.isOpen = !notificationsStore.isOpen;

  if (notificationsStore.isOpen) {
    notificationsStore.updateLastSeenAt();
  }
}
</script>

<template>
  <div ref="target" class="relative">
    <button
      type="button"
      class="ml-auto mr-0 rounded-full p-1 text-white hover:bg-blue-600 focus:ring-2 focus:ring-offset-2"
      @click="togglePanel"
    >
      <span class="sr-only">View notifications</span>

      <span
        v-if="notificationsStore.unreadCount > 0"
        class="absolute right-[-2px] top-[-2px] flex h-3 w-3"
      >
        <span
          class="absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75"
        ></span>
        <span
          class="relative inline-flex h-3 w-3 rounded-full border border-white bg-red-500"
        ></span>
      </span>

      <svg
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
        />
      </svg>
    </button>

    <div
      v-if="notificationsStore.isOpen"
      class="notification-panel overflow-auto"
    >
      <div class="sticky z-[2] flex justify-between border-b p-6">
        <h2 class="text-lg font-semibold">Notifications</h2>
        <button
          type="button"
          class="text-xs text-gray-700 hover:underline"
          @click="notificationsStore.markAllAsRead"
        >
          Mark all as read
        </button>
      </div>

      <div class="flex-1">
        <EnquiryNotificationCard
          v-for="notification in notificationsStore.notifications"
          :key="notification.id"
          :notification="notification"
        />
      </div>

      <button
        v-if="notificationsStore.hasMoreResults"
        type="button"
        class="w-full p-4 text-center text-sm font-medium hover:underline"
        @click="notificationsStore.fetchNextPage()"
      >
        Load more
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-panel {
  @apply absolute right-0 top-10 z-20 rounded-lg bg-white shadow-lg;
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: calc(90vh);

  @screen md {
    width: 500px;
  }
}
</style>
