<script setup lang="ts">
import { computed } from 'vue';
import { Dropdown } from 'flowbite';
import HeaderNavUserMenuLink from './HeaderNavUserMenuLink.vue';
import { User } from '~/types/user';
import { useMainStore } from '~/stores/mainStore';

const { user }: { user: User } = useAuth();

const name = computed(() => `${user?.first_name} ${user?.last_name}`);

const dropdownMenu = ref<HTMLElement | null>(null);
const dropdownButton = ref<HTMLElement | null>(null);
const dropdown = ref<Dropdown | null>(null);

const mainStore = useMainStore();

onMounted(() => {
  if (dropdownButton.value) {
    dropdown.value = new Dropdown(dropdownMenu.value, dropdownButton.value);
  }
});

const agentPortalVersionSwitchUrl = computed(() =>
  useZiggy('landlord.version', '1.0'),
);
</script>

<template>
  <div class="flex items-center" dusk="header-nav-user-menu">
    <!-- + User menu button -->
    <button
      id="user-menu-button"
      ref="dropdownButton"
      type="button"
      class="ml-4 mr-0 flex rounded-full bg-gray-800 text-sm ring-2 ring-white transition-all focus:ring-4 focus:ring-white dark:focus:ring-gray-600"
      aria-expanded="false"
      data-dropdown-toggle="user-dropdown"
      data-dropdown-placement="bottom"
      data-cy="header-nav-user-menu-button"
    >
      <span class="sr-only">Open user menu</span>
      <img
        class="h-8 w-8 rounded-full object-cover"
        :src="user?.src?.original?.url"
        :alt="user?.company"
      />
    </button>
    <!-- - User menu button -->
    <!-- + User dropdown menu -->
    <div
      id="user-dropdown"
      ref="dropdownMenu"
      class="!-left-2.5 z-50 my-4 !mt-5 hidden list-none divide-y divide-gray-100 rounded-lg bg-white text-base shadow dark:divide-gray-600 dark:bg-gray-700"
      data-cy="header-nav-user-menu-dropdown"
    >
      <div class="px-4 py-3">
        <span class="block text-sm text-gray-900 dark:text-white">{{
          name
        }}</span>
        <span class="block truncate text-sm text-gray-500 dark:text-gray-400">{{
          user?.email
        }}</span>
      </div>
      <ul class="py-2">
        <li>
          <HeaderNavUserMenuLink
            to="/account"
            title="Settings"
            :icon="['far', 'gear']"
          ></HeaderNavUserMenuLink>
        </li>
        <li>
          <HeaderNavUserMenuLink
            :to="useZiggy('home')"
            title="UniHomes"
            target="_blank"
            :icon="['far', 'arrow-up-right-from-square']"
          ></HeaderNavUserMenuLink>
        </li>
        <li>
          <HeaderNavUserMenuLink
            :to="useZiggy('page', ['website-terms'])"
            title="Terms and Conditions"
            target="_blank"
            :icon="['far', 'arrow-up-right-from-square']"
          ></HeaderNavUserMenuLink>
        </li>
        <li>
          <HeaderNavUserMenuLink
            :to="useZiggy('page', ['privacy-and-cookie-policies'])"
            title="Privacy policy"
            target="_blank"
            :icon="['far', 'arrow-up-right-from-square']"
          ></HeaderNavUserMenuLink>
        </li>
      </ul>
      <ul
        v-if="mainStore.siteSettings.showAgentPortalBetaToggleSwitch"
        class="py-2"
      >
        <li>
          <a
            class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-blue-500 hover:text-white dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
            :href="agentPortalVersionSwitchUrl"
          >
            <FontAwesomeIcon :icon="['far', 'sparkles']" class="mr-3" />Switch
            to legacy version
          </a>
        </li>
      </ul>
      <ul class="py-2">
        <li>
          <button
            type="button"
            class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-blue-500 hover:text-white dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
            @click="useLogOut()"
          >
            <FontAwesomeIcon
              :icon="['far', 'arrow-right-from-bracket']"
              class="mr-3"
            />Log out
          </button>
        </li>
      </ul>
    </div>
    <!-- - User dropdown menu -->
  </div>
</template>

<style scoped lang="scss"></style>
