<script setup lang="ts">
import UploadUtilityOrderModal from '@utilities/components/Modals/UploadUtilityOrderModal/UploadUtilityOrderModal.vue';
import { useSlots } from 'vue';
import AppHeader from '~/components/navigation/appHeader/AppHeader.vue';
import { useMainStore } from '~/stores/mainStore';

const { user } = await useAuth();
const { getAccountDetails, getSiteSettings, registerWebsocketEvents } =
  useMainStore();
const slots = useSlots();

getAccountDetails();
getSiteSettings();
registerWebsocketEvents(user.id);

const hasNavSlot = computed(() => {
  return typeof slots.nav !== 'undefined';
});
</script>

<template>
  <div class="flex h-screen w-screen flex-col" data-cy="default">
    <div class="flex w-full">
      <AppHeader :show-side-bar-button="hasNavSlot" />
    </div>

    <div class="flex flex-1 overflow-hidden relative">
      <slot name="nav"></slot>
      <slot name="page"></slot>
    </div>

    <UploadUtilityOrderModal />
  </div>
</template>
