<script setup lang="ts">
interface HeaderNavUserMenuLinkProps {
  cypress?: string;
  icon?: string[];
  iconClass?: string;
  linkClass?: string;
  title: string;
  to: string;
  target?: string;
}

const props = withDefaults(defineProps<HeaderNavUserMenuLinkProps>(), {
  cypress: undefined,
  icon: () => [],
  iconClass: '',
  linkClass: undefined,
  title: '',
  to: '',
  target: '',
});

const dataCy = computed(() =>
  props.cypress ? `${props.cypress}-header-nav-user-menu-link` : '',
);
</script>

<template>
  <NuxtLink
    :to="to"
    active-class="font-bold"
    class="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
    role="menuitem"
    :target="target"
    :class="linkClass"
    :data-cy="dataCy"
  >
    <div class="inline-flex items-center">
      <FontAwesomeIcon
        v-if="icon?.length"
        :icon="icon"
        class="mr-3"
        :class="iconClass"
      />
      {{ title }}
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss"></style>
